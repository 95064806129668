import React, { useEffect, useRef, useState } from 'react';
import { compose, withProps } from "recompose";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
} from "react-google-maps";
/* global google */

const Mapa = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places&language=pt-BR",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `350px`, width: `80%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
)(props => {

    const [directions, setDirections] = useState(null);
    const [zoomChanged, setZoomChanged] = useState(false);

    const mapRef = useRef(null);

    useEffect(() => {
        const DirectionsService = new google.maps.DirectionsService();

        DirectionsService.route(
            {
                origin: props.marker ? new google.maps.LatLng(props.marker) : new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng),
                destination: new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng),
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }, [props.dadosMapa.to_lat, props.dadosMapa.to_lng, props.marker]);


    return (
        <>
            <GoogleMap
                ref={mapRef}
                onZoomChanged={() => setZoomChanged(true)}
                defaultOptions={{
                    fullscreenControl: false,
                    // zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    // draggable: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles:
                        [
                            {
                                featureType: "administrative.land_parcel",
                                elementType: "labels",
                                stylers: [
                                    {
                                        visibility: "off"
                                    }
                                ]
                            },
                            {
                                featureType: "poi",
                                elementType: "labels.text",
                                stylers: [
                                    {
                                        visibility: "off"
                                    }
                                ]
                            },
                            {
                                featureType: "poi.business",
                                stylers: [
                                    {
                                        visibility: "off"
                                    }
                                ]
                            },
                            {
                                featureType: "poi.park",
                                elementType: "labels.text",
                                stylers: [
                                    {
                                        visibility: "off"
                                    }
                                ]
                            },
                            { featureType: "all", stylers: [{ saturation: 20 }] },
                            (props.marker ? {
                                featureType: "road.local",
                                elementType: "labels",
                                stylers: [
                                    {
                                        visibility: "off"
                                    }
                                ]
                            } : {
                                featureType: "road.local",
                                elementType: "labels",
                                stylers: [
                                    {
                                        visibility: "shown"
                                    }
                                ]
                            })
                        ]
                }}
            // https://mapstyle.withgoogle.com/
            // Se precisar mudar o estilo do mapa entrar nesse site estilizar e pegar o json com o estilo
            >
                <DirectionsRenderer
                    directions={directions}
                    options={{
                        suppressMarkers: props.marker ? true : false,
                        preserveViewport: zoomChanged === true ? true : false

                    }} />
                {props.marker && <Marker position={props.marker} icon={'/img/caminhao-esplane.png'} />}
                <Marker position={new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng)} icon={'/img/pedido-entregue.png'} />
            </GoogleMap>
        </>
    )
});

export default Mapa;
