import React, { Component } from 'react';


import {
  BrowserRouter as Router
  , Route
} from 'react-router-dom'


import Rastreamento from './components/rastreamento/Rastreamento';

import Nfe from './components/rastreamento/Nfe';



import './App.css';

class App extends Component {



  render() {

    const Child = ({ match }) => {
      //eslint-disable-next-line 
      if (match.params.origem == 'nfe') {
        return <Nfe
          origem={match.params.origem}
          codigo={match.params.codigo}
        />
        //eslint-disable-next-line 
      } else if (match.params.origem == 'voceconstroi' || match.params.origem == 'esplane') {
        return <Rastreamento
          origem={match.params.origem}
          codigo={match.params.codigo}
        />
      }

    }





    return (
      <Router>
        <Route path={'/:origem/:codigo'} component={Child} />
      </Router>
    )
  }


}

export default App
