import React, { useEffect, useState } from 'react';
import { Block, ContainerDescricao, ContainerStatus, IndicesColumn, StatusRow, TextoDescrição } from './styles';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import axios from 'axios';

function StatusComponent(props) {

    const [toAddress, setToAddress] = useState('');
    //eslint-disable-next-line 
    const [fromAddress, setFromAddress] = useState('');

    function formatDate(date, hour) {
        if (date !== null) {

            if (hour === 'hour') {
                const data = moment(date).format('DD/MM/YYYY');
                const hora = moment(date).format('HH:mm');

                return data + ' às ' + hora
            }
            return moment(date).format('DD/MM/YYYY');
        }
        else {
            return '';
        }
    }

    function formatAddress(addressComponent) {
        const filteredAddressTo = addressComponent.reduce((acc, component) => {
            if (!component.types.includes('postal_code') && !component.types.includes('country')) {
                if (component.types.includes('route')) {
                    return `${component.long_name}, ${acc}`.trim();
                } else if (component.types.includes('street_number')) {
                    return `${acc} ${component.long_name}`.trim();
                } else {
                    return `${acc}, ${component.long_name}`.trim();
                }
            }
            return acc;
        }, '').trim();

        return filteredAddressTo
    }

    useEffect(() => {
        const fetchAddress = async () => {
            try {
                const responseTo = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.dadosStatus.to_lat},${props.dadosStatus.to_lng}&key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3&language=pt-BR`);
                const responseFrom = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.dadosStatus.from_lat},${props.dadosStatus.from_lng}&key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3&language=pt-BR`);
                if (responseTo.data.status && responseFrom.data.status === 'OK') {

                    const addressComponentsTo = responseTo.data.results[0].address_components;
                    const addressComponentsFrom = responseFrom.data.results[0].address_components;

                    setFromAddress(formatAddress(addressComponentsFrom));
                    setToAddress(formatAddress(addressComponentsTo));
                } else {
                    console.log('Não foi possível obter o endereço.');
                }
            } catch (error) {
                console.log('Endereço ainda não disponivel');
            }
        };

        fetchAddress();
    }, [props.dadosStatus.from_lat, props.dadosStatus.from_lng, props.dadosStatus.to_lat, props.dadosStatus.to_lng]);


    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '40px' }}>

            <ContainerStatus mobile={props.mobile}>
                <IndicesColumn>
                    <Block mobile={props.mobile}>
                        <h4>Status</h4>
                    </Block>
                    <Block mobile={props.mobile}>
                        <h4>Movimentação</h4>
                    </Block>
                </IndicesColumn>
                {props.dadosStatus.dtentrega && (
                    <StatusRow>
                        <Block mobile={props.mobile}>
                            <Icon name='check' size='big' color="blue" />
                            <ContainerDescricao mobile={props.mobile} >
                                <TextoDescrição>Objeto Entregue</TextoDescrição>
                                <TextoDescrição>{formatDate(props.dadosStatus.dtentrega, 'hour')}</TextoDescrição>
                            </ContainerDescricao>

                        </Block>
                        <Block mobile={props.mobile}>

                            <div>
                                <TextoDescrição>Os produtos da sua NF {props.dadosStatus.numnota}  foram entregues em {formatDate(props.dadosStatus.dtentrega, 'hour')}.
                                    Quem recebeu foi  {props.dadosStatus.recebedor}, documento: {props.dadosStatus.docrecebedor}.</TextoDescrição>
                            </div>

                        </Block>
                    </StatusRow>
                )}
                {props.dadosStatus.dtinicentr && (
                    <StatusRow>
                        <Block mobile={props.mobile}>
                            <Icon name='truck' size='big' color="blue" />
                            <ContainerDescricao mobile={props.mobile} >
                                <TextoDescrição>Objeto Encaminhado</TextoDescrição>
                                <TextoDescrição>{formatDate(props.dadosStatus.dtinicentr, 'hour')}</TextoDescrição>
                            </ContainerDescricao>

                        </Block>
                        <Block mobile={props.mobile}>

                            <div>
                                {/* Verificar se é melhor colocar o endereço da onde está saindo para realizar a entrega no endereço do cliente */}
                                <TextoDescrição>Estamos saindo agora a caminho do seu endereço de entrega: {toAddress}.</TextoDescrição>
                            </div>

                        </Block>
                    </StatusRow>
                )}
                {props.dadosStatus.dtenvio && (
                    <StatusRow>
                        <Block mobile={props.mobile}>
                            <Icon name='truck' size='big' color="blue" />
                            <ContainerDescricao mobile={props.mobile} >
                                <TextoDescrição>Objeto Encaminhado</TextoDescrição>
                                <TextoDescrição>{formatDate(props.dadosStatus.dtenvio, 'hour')}</TextoDescrição>
                            </ContainerDescricao>

                        </Block>
                        <Block mobile={props.mobile}>

                            <div>
                                <TextoDescrição>Boa notícia: saímos do nosso Centro de Distribuição, no Bairro Vila Boa Vista - Campinas - SP, com os produtos de sua NF: {props.dadosStatus.numnota}.</TextoDescrição>
                            </div>

                        </Block>
                    </StatusRow>
                )}
                {props.dadosStatus.dtfatur && (
                    <StatusRow>
                        <Block mobile={props.mobile}>
                            <Icon name='dolly' size='big' color="blue" />
                            <ContainerDescricao mobile={props.mobile} >
                                <TextoDescrição>Pedido em separação</TextoDescrição>
                            </ContainerDescricao>

                        </Block>
                        <Block mobile={props.mobile}>

                            <div>
                                <TextoDescrição>O seu objeto está em separação no nosso centro logístico</TextoDescrição>
                            </div>

                        </Block>
                    </StatusRow>
                )}
            </ContainerStatus>


        </div>
    )
}

export default StatusComponent;
