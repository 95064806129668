import styled from 'styled-components';

export const ContainerHeader = styled.div`
    width: 100%;
    border-bottom: 3px #ededed solid;
    margin-bottom: 30px;
    padding: 10px 0px;
    display: flex;
   justify-content: center;
`;

export const ContainerStatus = styled.div`
    width: ${props =>
        `${props.mobile === true ? '100%' : '60%'}`};
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 50px;
`;

export const StatusRow = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const IndicesColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const StatusDescrição = styled.div`
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
`;

export const Block = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: ${props =>
        `${props.mobile === true ? 'column' : 'row'}`};
    margin-top: 30px;
`;

export const TextoDescrição = styled.p`
    color: '#9da3a1';
    margin: 0;
    text-align: center;
`;

export const SeparadorLinha = styled.div`
    width: 100%;
    border-bottom: 2px solid #ededed;
    margin-bottom: 20px;
`;

export const ContainerDescricao = styled.div`
margin-left: 
${props =>
        `${props.mobile === true ? 0 : '40px'}`};

margin-top:
${props =>
        `${props.mobile === true ? '20px' : 0}`};
`;
