import React from 'react';
import { Button } from 'semantic-ui-react';

function CardEntrega(props) {

    return (
        <>
            {props.mobile ? (
                <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column', marginTop: '30px', marginBottom: '50px', borderTop: '2px solid #e9e9e9', borderBottom: '2px solid #e9e9e9', padding: '20px 0px' }}>
                    <h3>Dados da entrega:</h3>

                    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h4>Nome do recebedor:</h4>
                            <p style={{ textAlign: 'center' }}>{props.dados.recebedor}</p>
                        </div>


                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h4>Doc. do recebedor:</h4>
                            <p>{props.dados.docrecebedor}</p>
                        </div>

                    </div>

                    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px' }}>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button onClick={props.handleAbrirModal} primary>Ver detalhes</Button>
                        </div>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        </div>


                    </div>


                </div>
            ) : (
                <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column', marginTop: '30px', marginBottom: '50px', borderTop: '2px solid #e9e9e9', borderBottom: '2px solid #e9e9e9', padding: '20px 0px' }}>
                    <h3>Dados da entrega:</h3>

                    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h4>Nome do recebedor:</h4>
                            <p> {props.dados.recebedor}</p>
                        </div>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h4>Documento do recebedor:</h4>
                            <p>{props.dados.docrecebedor}</p>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }} >
                                <h4>Detalhes da entrega:</h4>
                            </div>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button onClick={props.handleAbrirModal} primary>Ver detalhes</Button>
                            </div>
                        </div>


                    </div>


                </div>
            )}

        </>
    );
}

export default CardEntrega;

