import React from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

//Cada skeleton separado por componente
function SkeletonNomeHeader() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <Skeleton height={28} width={150} />
                <Skeleton height={28} width={200} />
            </div>
        </div>
    );
}

function SkeletonPedidoHeader() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <Skeleton height={20} width={200} />
                <Skeleton height={20} width={150} />
                <Skeleton height={20} width={100} />
            </div>
        </div>
    );
}
function SkeletonPedidoHeaderMobile() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <Skeleton height={20} width={150} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={70} />
            </div>
        </div>
    );
}

function SkeletonStepper() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={5} width={100} style={{ marginLeft: '20px' }} />
                    <Skeleton height={50} width={50} circle={true} style={{ marginLeft: '20px' }} />
                    <Skeleton height={5} width={100} style={{ marginLeft: '20px' }} />
                    <Skeleton height={50} width={50} circle={true} style={{ marginLeft: '20px' }} />
                    <Skeleton height={5} width={100} style={{ marginLeft: '20px' }} />
                    <Skeleton height={50} width={50} circle={true} style={{ marginLeft: '20px' }} />
                    <Skeleton height={5} width={100} style={{ marginLeft: '20px' }} />
                    <Skeleton height={50} width={50} circle={true} style={{ marginLeft: '20px' }} />
                    <Skeleton height={5} width={100} style={{ marginLeft: '20px' }} />
                    <Skeleton height={50} width={50} circle={true} style={{ marginLeft: '20px' }} />
                </div>
            </div>
        </div>
    );
}

function SkeletonStatus() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <div style={{ marginTop: '150px', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ marginRight: '100px' }}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={20} width={200} style={{ marginTop: '20px' }} />
                        <Skeleton height={20} width={150} />
                        <Skeleton height={20} width={100} />
                    </div>
                    <div style={{ marginLeft: '100px' }}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={20} width={200} style={{ marginTop: '20px' }} />
                        <Skeleton height={20} width={150} />
                        <Skeleton height={20} width={100} />
                    </div>
                </div>
            </div>
        </div>
    );
}
function SkeletonStepperMobile() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', flexDirection: 'column', marginLeft: '30px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Skeleton height={50} width={50} circle={true} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton height={100} width={5} style={{ marginTop: '20px', marginLeft: '20px' }} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Skeleton height={50} width={50} circle={true} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton height={100} width={5} style={{ marginTop: '20px', marginLeft: '20px' }} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Skeleton height={50} width={50} circle={true} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton height={100} width={5} style={{ marginTop: '20px', marginLeft: '20px' }} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Skeleton height={50} width={50} circle={true} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton height={100} width={5} style={{ marginTop: '20px', marginLeft: '20px' }} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Skeleton height={50} width={50} circle={true} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton height={100} width={5} style={{ marginTop: '20px', marginLeft: '20px' }} />
                        <Skeleton height={15} width={100} style={{ marginLeft: '20px' }} />
                    </div>
                    <Skeleton height={50} width={50} circle={true} style={{ marginTop: '20px' }} />
                </div>
            </div>
        </div>
    );
}

export { SkeletonNomeHeader, SkeletonPedidoHeader, SkeletonStepper, SkeletonStatus, SkeletonStepperMobile, SkeletonPedidoHeaderMobile };